import React, {Fragment} from 'react'
import {Link as GatsbyLink} from 'gatsby'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    mainLinks: {
        color: 'inherit',
        fontSize: '1rem',
        margin: '0 .8rem',
        fontFamily: 'Sawarabi Mincho'
    }
})


const MenuLinks = () => {
const classes = useStyles();
    return (
        <Fragment>
        <Link className={classes.mainLinks} component={GatsbyLink} to='/'>Home</Link>
        <Link className={classes.mainLinks} component={GatsbyLink} to='/blog'>Blog</Link>
        <Link className={classes.mainLinks} component={GatsbyLink} to='/archive'>Archive</Link>
       </Fragment>
    );
}

export default MenuLinks