import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import Fade from '@material-ui/core/Fade'

const Wrapper = ({children}) => {
  return  (
    <React.Fragment>
    <CssBaseline />
    <Container maxWidth="lg">
    <Fade in>
      <Typography component="div" style={{ padding: '1rem', marginBottom: '1rem'}}>
      {children}
      </Typography>
      </Fade>
      </Container>
  </React.Fragment>
    )
}

export default Wrapper
