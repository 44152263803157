import React from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded'

import MenuSharpIcon from '@material-ui/icons/MenuSharp'

import Box from '@material-ui/core/Box';
import MobileLinks from './mobile-links'



export default function MobileMenu() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
 

  const list = (anchor) => (
    <div
      style={{width: '100vw'}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
<Box margin='.5rem'>
<ChevronRightRoundedIcon/>
</Box>
     <Box display='flex' marginY='5rem' fontSize='22px'  flexDirection='column' alignItems='center' justifyContent='center' >
       <MobileLinks />
    </Box>
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
        <IconButton onClick={toggleDrawer(anchor, true)} color="inherit" aria-label="Toggle Mobile Menu">
        <MenuSharpIcon />
         </IconButton>
         <React.Fragment>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
          </React.Fragment>
        </React.Fragment>
      ))}
    </div>
  );
}
