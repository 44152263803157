import React from 'react'
import {Link as GatsbyLink} from 'gatsby'
import { TiAdjustBrightness } from 'react-icons/ti'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Link from '@material-ui/core/Link'
import MobileMenu from './mobile-menu'
import MenuLinks from './menu-links'

const useStyles = makeStyles({
  mainLinks: {
      color: 'inherit',
      fontSize: '1rem',
      margin: '0 .8rem',
      fontFamily: 'Sawarabi Mincho'
  }
})

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 10 : 0,
  });
}

function Header({ siteTitle }, props) {
  const classes = useStyles();
  return (
    <ElevationScroll {...props}>
 <AppBar color='default'>
   <Container disableGutters maxWidth='lg'>
   <Toolbar>
     <Hidden mdUp>
        <MobileMenu/>
      </Hidden>
      <img style={{width: '20px', paddingBottom: '.5rem'}} src="/logo.svg" alt='Apple' /> 
     <Link className={classes.mainLinks} component={GatsbyLink} to='/'>{siteTitle}</Link>
     <Hidden smDown>
     <MenuLinks/>
    </Hidden>
    </Toolbar>
    </Container>
    </AppBar>
    </ElevationScroll>
  )}

export default Header
