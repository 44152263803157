import React, {Fragment} from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Header from './header'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'


const Layout = ({children, siteTitle}) => (
  <Fragment>
    <Helmet>
      <link rel='icon' href={favicon} />
    </Helmet>
   
    <Header siteTitle={siteTitle}  />
      <Container disableGutters style={{maxWidth: '1600px',height: '100%', paddingTop:'3.4rem'}}>
        
          {children}
  

          </Container>

      <Typography component='div'>
        <Box width='100vw' textAlign='center'>
        &copy; 2016 - {new Date().getFullYear()}, Edwin Bartunek <br/> A <a href='https://devhart.io'>DevHart.IO</a> Production
        </Box>
      </Typography>
      
  </Fragment>
)

export default Layout
