import React, {Fragment} from 'react'
import clsx from 'clsx'
import {Link as GatsbyLink} from 'gatsby'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    mLinks: {
        color: 'inherit',
        fontSize: '2.1rem',
        margin: '0 2rem',
        lineHeight: '5.5rem'
    }
})


const MenuLinks = (props) => {
const classes = useStyles();
    return (
        <Fragment>
        <Link className={classes.mLinks} component={GatsbyLink} to='/'>Home</Link>
        <Link className={classes.mLinks} component={GatsbyLink} to='/blog'>Blog</Link>
        <Link className={classes.mLinks} component={GatsbyLink} to='/archive'>Archive</Link>
       </Fragment>
    );
}

export default MenuLinks